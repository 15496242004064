/** @jsx jsx */
import { jsx } from "theme-ui";
import DLWLayout from "../../../layouts/dlw-fixed";

import AnnouncementsBoardAdmin from "../../../components/Trainer/AnnouncementsBoard/admin";

function SpecialExercise() {
  return (
    <DLWLayout sx={{ bg: "white", pb: 4 }}>
      <AnnouncementsBoardAdmin />
    </DLWLayout>
  );
}

export default SpecialExercise;
